<template>
  <CRow class="mb-5">
    <CCol lg="5" xl="5" xxl="4">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="6">
                <TMessage content="From date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.start"
                  timeline="start"
                  placeholder="Start date"
                  class="mb-2"
                  :key="startDateKey"
                />
              </CCol>
              <CCol col="6">
                <TMessage content="To date" bold class="mb-2" />
                <TInputDateTime
                  :value.sync="filter.end"
                  timeline="end"
                  placeholder="End date"
                  class="mb-2"
                  :key="endDateKey"
                />
              </CCol>
              <CCol col="12">
                <SSelectCustomer
                  :value.sync="filter.object_id"
                  prependAll
                  label="User"
                  class="mb-2"
                />
              </CCol>
              <CCol col="12">
                <TButton
                  class="w-100 mt-2"
                  content="Filter"
                  @click="onFilter"
                  :disabled="objects_fetching || !filter.end || !filter.start"
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem
            v-for="(payable, index) in objects"
            :key="`${index}`"
            :color="
              selected.object_id_selected == payable.walletable_id &&
              selected.currency_id == payable.currency_id
                ? 'primary'
                : 'secondary'
            "
            :to="
              !references_fetching
                ? _.getAppendRouteQuery($route, {
                    object_id_selected: payable.walletable_id,
                    currency_id: payable.currency_id,
                  })
                : ''
            "
          >
            <div class="d-flex">
              <SMessageWallet :data="payable" />
              <TMessageMoney
                :amount="payable.balance"
                class="ml-auto"
                :currency="payable.currency_id"
              />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="objects_fetching">
            <TSpinner :loading="objects_fetching" />
          </CListGroupItem>
          <CListGroupItem v-if="!objects.length">
            <TMessageNotFound />
          </CListGroupItem>
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="7" xl="7" xxl="8">
      <div class="mb-3" v-if="selected.currency_id === 'JPY'">
        <ExportCustomerDebt :debts="data" :current_wallet="selected" />
      </div>
      <TTableSimple
        v-if="selected.object_id_selected && selected.currency_id"
        :items="data || []"
        :fields="fields"
        size="lg"
        columnFilter
        :loading="references_fetching"
        :options="{ sorter: true }"
        class="table-custom"
      >
        <template #_="{ item, index }">
          <td :style="item._toggled ? 'border-left: 1px solid #477a8b' : ''">
            <TButton
              icon="cil-eye"
              tooltip="View"
              color="primary"
              variant="outline"
              size="sm"
              @click="viewReceipt(item)"
            />
            <TButton
              class="ml-1"
              color="primary"
              variant="outline"
              size="sm"
              tooltip="Expand"
              icon="cilExpandRight"
              @click="toggleDetails(item, index)"
              v-if="lodash.has(item, 'append_objects')"
            />
          </td>
        </template>
        <template #receiptable_type="{ item }">
          <td>
            <TMessage
              :content="
                lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
              "
            />
          </td>
        </template>
        <template #receiptable_type-filter>
          <TSelect
            class="m-0"
            :options="receiptableType"
            :value="receiptableSelected"
            :selectOptions="{
              internalSearch: true,
              preserveSearch: true,
            }"
            :normalizer="{ label: 'name', value: 'id' }"
            placeholder="Select"
            @select="receiptableSelected = $event"
          />
        </template>
        <template #receiptable_id-filter>
          <TInputText placeholder="Voucher code" :value.sync="receptableId" />
        </template>
        <template #_-filter>
          <TButtonClear
            variant="outline"
            class="mb-1 ml-2"
            @click="clearFilter"
          />
        </template>
        <template #receiptable_id="{ item }">
          <td>
            <TMessage :content="item.receiptable_id" noTranslate />
          </td>
        </template>
        <template #paid="{ item }">
          <td class="px-0">
            <div class="d-flex flex-wrap px-2" v-if="lodash.has(item, 'paid')">
              <TMessage
                v-if="item.append_objects && item.append_objects.length"
                :content="
                  lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
                "
                class="mr-1"
                ><template #suffix>:</template>
              </TMessage>
              <TMessageMoney :amount="item.paid" :currency="item.currency_id" />
            </div>
            <hr
              class="my-2"
              style="opacity: 0.5"
              v-if="
                item.append_objects &&
                item.append_objects.length &&
                lodash.has(item, 'paid')
              "
            />
            <div
              class="d-flex flex-wrap px-2"
              v-if="item.append_objects && item.append_objects.length"
            >
              <TMessage content="Order" />:
              <TMessageMoney
                class="ml-1"
                :amount="item.append_objects_paid"
                :currency="item.currency_id"
              />
            </div>
          </td>
        </template>
        <template #debited="{ item }">
          <td class="px-0">
            <div
              class="d-flex flex-wrap px-2"
              v-if="lodash.has(item, 'debited')"
            >
              <TMessage
                v-if="item.append_objects && item.append_objects.length"
                :content="
                  lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
                "
                class="mr-1"
                ><template #suffix>:</template>
              </TMessage>
              <TMessageMoney
                :amount="item.debited"
                :currency="item.currency_id"
              />
            </div>
            <hr
              class="my-2"
              style="opacity: 0.5"
              v-if="
                item.append_objects &&
                item.append_objects.length &&
                lodash.has(item, 'debited')
              "
            />
            <div
              class="d-flex flex-wrap px-2"
              v-if="item.append_objects && item.append_objects.length"
            >
              <TMessage content="Order" />:
              <TMessageMoney
                class="ml-1"
                :amount="item.append_objects_debited"
                :currency="item.currency_id"
              />
            </div>
          </td>
        </template>
        <template #outstanding="{ item }">
          <td class="px-0">
            <div
              class="d-flex flex-wrap px-2"
              v-if="lodash.has(item, 'outstanding')"
            >
              <TMessage
                v-if="item.append_objects && item.append_objects.length"
                :content="
                  lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
                "
                class="mr-1"
                ><template #suffix>:</template>
              </TMessage>
              <TMessageMoney
                :amount="item.outstanding"
                :currency="item.currency_id"
              />
            </div>
            <hr
              class="my-2"
              style="opacity: 0.5"
              v-if="
                item.append_objects &&
                item.append_objects.length &&
                lodash.has(item, 'outstanding')
              "
            />
            <div
              class="d-flex flex-wrap px-2"
              v-if="item.append_objects && item.append_objects.length"
            >
              <TMessage content="Order" />:
              <TMessageMoney
                class="ml-1"
                :amount="item.append_objects_outstanding"
                :currency="item.currency_id"
              />
            </div>
          </td>
        </template>
        <template #result-debited>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('debited')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #result-paid>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('paid')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #result-outstanding>
          <th>
            <div class="d-flex">
              <TMessage content="Total:" />
              <i>
                <TMessageMoney
                  :amount="getTotal('outstanding')"
                  :currency="selected.currency_id"
                  class="ml-1"
                />
              </i>
            </div>
          </th>
        </template>
        <template #details="{ item }">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            class="mb-3"
          >
            <TTableSimple
              v-if="item.append_objects && item._toggled"
              :items="item.append_objects"
              :fields="childFields"
              size="lg"
              :loading="references_fetching"
              noResult
              :options="{ header: false, striped: true }"
            >
              <template #_="{ item }">
                <td style="width: 120px; min-width: 120px">
                  <TButton
                    icon="cil-eye"
                    tooltip="View"
                    color="primary"
                    variant="outline"
                    size="sm"
                    @click="viewReceipt(item)"
                  />
                </td>
              </template>
              <template #receiptable_type="{ item }">
                <td style="width: 140px; min-width: 140px">
                  <TMessage
                    :content="
                      lodash.upperFirst(lodash.lowerCase(item.receiptable_type))
                    "
                  />
                </td>
              </template>
              <template #receiptable_id="{ item }">
                <td style="width: 120px; min-width: 120px">
                  <TMessage :content="item.receiptable_id" noTranslate />
                </td>
              </template>
              <template #paid="{ item }">
                <td class="text-nowrap" style="width: 220px; min-width: 220px">
                  <TMessageMoney
                    :amount="item.paid"
                    :currency="item.currency_id"
                  />
                </td>
              </template>
              <template #debited="{ item }">
                <td class="text-nowrap" style="width: 220px; min-width: 220px">
                  <TMessageMoney
                    :amount="item.debited"
                    :currency="item.currency_id"
                  />
                </td>
              </template>
              <template #outstanding="{ item }">
                <td style="width: 220px; min-width: 220px">
                  <TMessageMoney
                    :amount="item.outstanding"
                    :currency="item.currency_id"
                  />
                </td>
              </template>
            </TTableSimple>
          </CCollapse>
        </template>
      </TTableSimple>
    </CCol>
    <ModalTransactions
      v-if="showDetailVocherModal"
      :show.sync="showDetailVocherModal"
      :voucher="voucher_selected"
      :default_filter="{
        'filter_between[voucher_date]': `${filter.start},${filter.end}`,
      }"
      @updated="fetchDetail()"
    />
  </CRow>
</template>

<script>
import ModalTransactions from "../../components/ModalTransactions";
import ExportCustomerDebt from "../components/ExportCustomerDebt";
import debtMixin from "../../mixins/debtMixin";

export default {
  components: { ModalTransactions, ExportCustomerDebt },
  mixins: [debtMixin],
  data() {
    return {
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "receiptable_type",
          label: "Receipts type",
          _classes: "",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "receiptable_id",
          label: "Voucher",
          _classes: "text-nowrap",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "debited",
          label: "Debited",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "paid",
          label: "Paid",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "outstanding",
          label: "Outstanding",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
      ],
      childFields: [
        { key: "_" },
        { key: "receiptable_type" },
        { key: "receiptable_id" },
        { key: "debited" },
        { key: "paid" },
        { key: "outstanding" },
      ],
      receiptableType: [
        { id: "", name: this.$t("All") },
        { id: "contract", name: this.$t("Contract") },
        { id: "lading_bill", name: this.$t("Lading bill") },
        { id: "order", name: this.$t("Order") },
      ],
      object_type: "user",
    };
  },
  methods: {
    getTotal(key) {
      const sumParent = this.lodash.sumBy(this.data, key) || 0;
      const sumChild =
        this.lodash.sumBy(this.data, `append_objects_${key}`) || 0;
      return Math.round((sumParent + sumChild) * 100) / 100 || 0;
    },
    toggleDetails(item, index) {
      this.references.data.forEach((item, i) => {
        if (index == i) return;
        this.$delete(this.references.data[i], "_toggled", !item._toggled);
      });

      this.$set(this.references.data[index], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
};
</script>

<style scoped>
* >>> .table-custom > .table-responsive {
  min-height: 280px;
}
</style>
