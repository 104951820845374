<template>
  <TModal
    title="Transactions"
    :show="show"
    size="xl"
    @update:show="$emit('update:show', $event)"
  >
    <TableTransactions
      store="accounting.transactions"
      :default_filter="defaultFilter"
      :key="`table-transactions-${voucher.receiptable_type}-${voucher.receiptable_id}-${voucher.currency_id}`"
      @updated="updated = true"
    />
    <template #actions>
      <div></div>
    </template>
  </TModal>
</template>

<script>
import TableTransactions from "./TableTransactions.vue";

export default {
  components: { TableTransactions },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    voucher: {
      type: Object,
      required: true,
    },
    default_filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      updated: false,
    };
  },
  watch: {
    show(show) {
      if (!show && this.updated) {
        this.$emit("updated");
        this.updated = false;
      }
    },
  },
  computed: {
    defaultFilter() {
      return {
        "filter[receipts.receiptable_type]": this.voucher.receiptable_type,
        "filter[receipts.receiptable_id]": this.voucher.receiptable_id,
        "filter[currency_id]": this.voucher.currency_id,
        ...this.default_filter,
      };
    },
  },
};
</script>
