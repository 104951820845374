export default {
  data() {
    return {
      objects: [],
      objects_fetching: false,
      references: [],
      references_fetching: false,
      filter: {
        object_id: "",
        start: this.lodash.toTimeStamp(Date.now(), "start"),
        end: this.lodash.toTimeStamp(Date.now(), "end"),
      },
      showDetailVocherModal: false,
      voucher_selected: {},
      collapseDuration: 0,
      receiptableSelected: { id: "", name: this.$t("All") },
      startDateKey: "startDateKey",
      endDateKey: "endDateKey",
      receptableId: "",
    };
  },
  computed: {
    selected() {
      return {
        object_id_selected: this.$route.query.object_id_selected,
        currency_id: this.$route.query.currency_id,
        wallet: this.objects.find(i => i.walletable_id === this.$route.query.object_id_selected)
      };
    },
    data() {
      return this.lodash.filter(
        this.references.data,
        this.lodash.pickBy({
          receiptable_type: this.receiptableSelected?.id,
          receiptable_id: this.receptableId,
        })
      );
    },
    queryRoute() {
      if (!this.lodash.isEmpty(this.$route.query)) {
        return this.lodash.omit(this.$route.query, [
          "currency_id",
          "object_id_selected",
        ]);
      }
      return {};
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler(selected, selected_old) {
        if (selected?.object_id_selected && selected.currency_id) {
          if (
            selected?.object_id_selected != selected_old?.object_id_selected ||
            selected.currency_id != selected_old.currency_id
          ) {
            this.clearFilter()
            this.fetchDetail(
              this.lodash.isEmpty(this.queryRoute)
                ? this.filter
                : this.queryRoute
            );
          }
        }
      },
    },
    filter: {
      immediate: true,
      deep: true,
      handler(filter) {
        if (filter.start > filter.end) {
          this.filter = {
            ...this.filter,
            start: this.lodash.toTimeStamp(filter.end * 1000, "start"),
            end: this.lodash.toTimeStamp(filter.start * 1000, "end"),
          };
          this.startDateKey = this.lodash.resetKey("startDateKey");
          this.endDateKey = this.lodash.resetKey("endDateKey");
        }
      },
    },
    queryRoute: {
      immediate: true,
      deep: true,
      handler(query, queryOld) {
        const queryApply = this.lodash.isEmpty(query) ? this.filter : query;
        if (!this.lodash.isEqual(query, queryOld)) {
          if (!queryOld) {
            this.fetchList(queryApply);
          } else {
            this.fetchList(queryApply);
            this.fetchDetail(queryApply);
          }
        }
        if (!this.lodash.isEmpty(query)) {
          this.filter = {
            ...query,
            start: +query.start,
            end: +query.end,
          };
        }
      },
    },
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          ...this.filter,
          start: this.filter.start.toString(),
          end: this.filter.end.toString(),
        },
      });
    },
    onFilter() {
      this.appendRoute();
      this.clearFilter();
    },
    fetchList(filter) {
      this.objects_fetching = true;
      this.$tomoni.accounting.statistics
        .getOutstandingObjects({
          "scope_date[start]": filter.start || "0",
          "scope_date[end]": filter.end || "0",
          object_type: this.object_type,
          object_id: filter.object_id,
        })
        .then(({ data }) => {
          this.objects = data;
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.objects_fetching = false;
        });
    },
    fetchDetail(filter) {
      if (!this.selected.object_id_selected) return;
      this.references_fetching = true;
      this.$tomoni.accounting.statistics
        .getOutstandingReferenceObjects({
          "scope_date[start]": filter.start || "0",
          "scope_date[end]": filter.end,
          "wallet[object_type]": this.object_type,
          "wallet[object_id]": this.selected.object_id_selected,
          "wallet[currency_id]": this.selected.currency_id,
        })
        .then(({ data }) => {
          this.references = data;
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.references_fetching = false;
        });
    },
    viewReceipt(item) {
      this.voucher_selected = item;
      this.showDetailVocherModal = true;
    },
    clearFilter() {
      this.receptableId = "";
      this.receiptableSelected = { id: "", name: this.$t("All") };
    },
  },
}
