<template>
  <TButton
    content="Export CSV"
    @click="exportCSV"
    :disabled="loading"
  />
</template>

<script>

import moment from 'moment';

export default {
  props: {
    debts: {
      type: Array,
      default: () => ([])
    },
    current_wallet: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      csvData: [],
      loading: false
    }
  },
  watch: {
    current_wallet: {
      immediate: true,
      handler() {
        this.csvData = []
      }
    }
  },
  methods: {
    convertColSlugToNumber(col_slug) {
      return Math.max(col_slug.charCodeAt(0) - 65, 0);
    },

    fillCell(row, col, cell_data) {
      if (!this.csvData[row]) {
        for (let r_idx = 0; r_idx <= row; r_idx++) {
          if (!this.csvData[r_idx]) this.csvData[r_idx] = [];
        }
      }

      if (!this.csvData?.[row]?.[col]) {
        for (let c_idx = 0; c_idx <= col; c_idx++) {
          if (!this.csvData[row][c_idx]) this.csvData[row][c_idx] = ''
        }
      }

      this.csvData[row][col] = `"${cell_data}"`;
    },

    getTotalPaid() {
      return this.debts.reduce((p, c) => {
        if(c.receiptable_type === 'contract') return p + c.append_objects_paid;

        return p + c.paid;
      }, 0);
    },

    createTemplate(order_items) {

      let top_section = {
        '1-A': 'ご請求書',
        '4-A': 'User :',
        '4-B': this.current_wallet?.wallet?.object?.name || this.current_wallet?.walletable_id,
        '1-N': 'DATE',
        '2-N': 'SỐ DƯ\n未収金',
        '3-N': 'TỔNG MUA\n請求額',
        '4-N': 'TỔNG CHUYỂN\n入金',
        '1-O': moment(this.$route.query.end, 'X').format('YYYY/MM/DD'),
        '2-O': `¥${Number(this.current_wallet?.wallet?.balance)}`,
        '3-O': `¥${this.lodash.sumBy(order_items, 'balance')}`,
        '4-O': `¥${this.getTotalPaid()}`,
      }

      let table_product_header_section = {
        '5-J': 'TỔNG',
        '5-K': `¥${this.lodash.sumBy(order_items, 'balance')}`,
        '6-A': 'JAN code',
        '6-B': 'Tên hàng\nProduct name',
        '6-C': 'Hình ảnh\nPicture',
        '6-D': 'Số thùng\nCTNS',
        '6-E': 'Số lượng/thùng\nNumber of boxes',
        '6-F': 'Tổng số lượng\nQTY',
        '6-G': 'Đơn giá\nSELL PRICE\n(C&F)',
        '6-H': 'Tổng phụ\nSubtotal',
        '6-I': 'Tax (%)',
        '6-J': 'Chiết khấu\nDiscount',
        '6-K': 'Tổng tiền\nTotal money',
        '6-L': 'Web Order',
      }

      let table_total_debt = {
        '6-R': 'TOTAL',
        '6-S': 'Payment day',
        '6-T': 'Số tiền\nAmount of money',
        '7-Q': 'Số dư\n未収金',
        '7-R': `¥${Number(this.current_wallet?.wallet?.balance)}`,
        '7-S': `${moment(this.$route.query.end, 'X').month() + 1}月末残高`,
      }

      let start_of_bank_info_row = (order_items?.length || 0) + 7 + 4;

      let card_and_bank_info = {
        [`${start_of_bank_info_row}-A`]: '備考',
        [`${start_of_bank_info_row}-G`]: 'SELLER   :  tomoniロジスティクス株式会社',
        [`${start_of_bank_info_row + 1}-G`]: '本店 ： 321-0158　栃木県宇都宮市西川田本町2-1-1    千葉営業所： 289-1501  千葉県山武市松尾町山室121-2',
        [`${start_of_bank_info_row + 2}-G`]: 'TEL 028-680-5696　FAX 028-680-7106',
        [`${start_of_bank_info_row + 4}-A`]: 'Please remit payment to the following bank account',
        [`${start_of_bank_info_row + 5}-A`]: 'Bank Name： RAKUTEN BANK, LTD.',
        [`${start_of_bank_info_row + 5}-G`]: 'Bank Name： SUMITOMO MITSUI BANKING CORPORATION',
        [`${start_of_bank_info_row + 6}-A`]: 'SWIFT Code / BIC　:RAKTJPJT( RAKTJPJTXXX)',
        [`${start_of_bank_info_row + 6}-G`]: 'SWIFT Code / BIC　:SMBCJPJT',
        [`${start_of_bank_info_row + 7}-A`]: 'Branch Name： HEAD OFFICE',
        [`${start_of_bank_info_row + 7}-G`]: 'Branch Name： OYAMA BRANCH',
        [`${start_of_bank_info_row + 8}-A`]: '店番号：251　口座番号：7991468',
        [`${start_of_bank_info_row + 8}-G`]: '店番号　：022 普通 4539482',
        [`${start_of_bank_info_row + 9}-A`]: '受取人口座名義：　TOMONI LOGISTICS.JP',
        [`${start_of_bank_info_row + 9}-G`]: '受取人口座名義：　TOMONI LOGISTICS.JP',
        [`${start_of_bank_info_row + 10}-A`]: '取引支店住所：NBF Shinagawa Tower, 2-16-5 Konan, Minato-ku, Tokyo ',
        [`${start_of_bank_info_row + 10}-G`]: '取引支店住所：3-4-1, SHIROYAMACHO,OYAMA-SHI, TOCHIGI  323-0025, JAPAN',
      }

      Object.entries({
        ...top_section,
        ...table_product_header_section,
        ...table_total_debt,
        ...card_and_bank_info
      }).forEach(([position, cell_data]) => {
        let [row, col_slug] = position.split('-');

        let col = this.convertColSlugToNumber(col_slug);

        this.fillCell(Number(row), col, cell_data);
      });
    },

    getOrderIds() {
      let order_ids = [];

      order_ids = this.debts.filter(i => i.receiptable_type === 'order').map(i => i.receiptable_id);

      this.debts.filter(i => i.receiptable_type === 'contract').forEach((contract) => {
        order_ids = order_ids.concat((contract?.append_objects || []).filter(i => i.receiptable_type === 'order').map(i => i.receiptable_id))
      });

      return order_ids;
    },

    getOrderItems(order_ids) {

      if(!order_ids.length) return [];

      let r = [];

      let api = (page = 1, results = []) => this.$tomoni.order.order_items.all({
        'filter[order_id]': order_ids.join(','),
        include: 'product',
        page
      })
        .then(res => res.data)
        .then(res => {
          if (res.last_page > page) return api(page + 1, [...results, ...res.data])

          return [...results, ...res.data];
        }).catch(() => {
          return results;
        });

      return api(1, r);
    },

    createOrderItemsToCSVData(order_items, start_row_index = 7) {
      let JPYSymbol = '¥';
      let table_product_row_frame = [
        'product_id',
        'product.name',
        'product.image_url',
        () => '-',
        () => '-',
        'quantity',
        (i) => JPYSymbol + i.price,
        (i) => JPYSymbol + i.amount,
        'tax_percent',
        'discount_tax_per_tax_percent',
        (i) => JPYSymbol + i.balance,
        'order_id'
      ];

      order_items.forEach((order_item, row_idx) => {

        table_product_row_frame.forEach((key, col_idx) => {
          let cell_data;

          if (typeof key === 'string') cell_data = this.lodash.get(order_item, key);
          else cell_data = key(order_item);

          this.fillCell(start_row_index + row_idx, col_idx, cell_data);
        })
      });
    },

    async exportCSV() {
      this.loading = true;
      let order_ids = this.getOrderIds();

      let order_items = await this.getOrderItems(order_ids);

      this.createTemplate(order_items);
      this.createOrderItemsToCSVData(order_items);

      let csvContent =
        "data:text/csv;charset=utf-8," +
        this.csvData.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.current_wallet?.wallet?.object?.name || this.current_wallet?.walletable_id}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
      this.loading = false;
    }
  },
};
</script>
